import { CloudUpload, Settings } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  Divider,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import * as z from "zod";
import { getAppConfig } from "~/apps/studio/config";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import {
  FileField,
  requiredFile,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { DocumentTitle } from "~/components/document-title";
import { requiredText, requiredUuid } from "~/domain/common";
import { MultipartUploader } from "~/domain/network";
import { formatBytes, formatPercent } from "~/format";
import * as Layout from "~/layout";
import {
  LayoutStateProvider,
  ScrollableContainer,
  SidebarSwitch,
  SidebarTrigger,
} from "~/layout";
import { isEmpty } from "~/lib/std";
import { NOTEBOOK_HELP_LINK } from "~/links";
import type { Log } from "~/lqs";
import { GroupSelectField } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { SettingsSidebar } from "~/settings";
import { buildLqsRestApiDocsEndpoint } from "~/utils/build-api-endpoints";
import { UploadResponseError, useUploadLog } from "./queries";

const schema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  file: requiredFile,
});

export function UploadPage() {
  const uploadLog = useUploadLog();

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useStudioForm({
    schema,
    defaultValues: {
      name: null,
      groupId: null,
      file: null,
    },
    onSubmit(values) {
      uploadLog.mutate(values, {
        onError(error) {
          if (error instanceof UploadResponseError) {
            if (error.type === "name:duplicate") {
              setError("name", {
                message: "A log with that name already exists",
              });
            }
          }
        },
      });
    },
  });

  return (
    <>
      <DocumentTitle>Upload a Log</DocumentTitle>
      <LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title="Upload"
            actions={
              <SidebarTrigger
                title="Settings"
                sidebarId="settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            <ScrollableContainer>
              <Container fixed>
                <Stack spacing={4}>
                  <Card title="New Log">
                    <Stack
                      spacing={2}
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ alignItems: "baseline" }}
                    >
                      <Box sx={{ width: "40ch" }}>
                        <TextField control={control} name="name" required />
                      </Box>
                      <Box sx={{ width: "25ch" }}>
                        <GroupSelectField
                          control={control}
                          name="groupId"
                          required
                        />
                      </Box>
                      <Box sx={{ alignSelf: "stretch" }}>
                        <FileField
                          control={control}
                          name="file"
                          setError={setError}
                          clearErrors={clearErrors}
                          accept={{
                            "application/octet-stream": [".bag", ".mcap"],
                          }}
                          acceptedFormats={[".bag", ".mcap"]}
                        />
                      </Box>
                      <Details>
                        <Details.Summary>
                          How can I upload logs larger than{" "}
                          {formatBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)}?
                        </Details.Summary>
                        <Details.Content paragraph>
                          To upload a log larger than{" "}
                          {formatBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)}{" "}
                          you'll need to use the{" "}
                          <Link color="inherit" href={buildDocsEndpoint()}>
                            DataStore REST API
                          </Link>{" "}
                          directly. Using the API, you can upload logs up to 5
                          TiB.
                        </Details.Content>
                        <Details.Content>
                          Follow this detailed guide on{" "}
                          <Link color="inherit" href={NOTEBOOK_HELP_LINK}>
                            using the API to upload large files
                          </Link>
                          .
                        </Details.Content>
                      </Details>
                      <Divider flexItem />
                      <Typography id="upload-warning">
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          Important:
                        </Typography>{" "}
                        Once you start uploading your log, leave this page open
                        until the upload is complete
                      </Typography>
                      <LoadingButton
                        aria-describedby="upload-warning"
                        type="submit"
                        color="primary"
                        variant="contained"
                        startIcon={<CloudUpload />}
                        loading={uploadLog.isLoading}
                        disabled={uploadLog.isSuccess}
                      >
                        Upload Log
                      </LoadingButton>
                      {uploadLog.isLoading && (
                        <Stack sx={{ width: 1, pt: 2 }}>
                          <Typography id="progress-label" paragraph>
                            {uploadLog.uploadStatus.isComplete
                              ? "Finishing up..."
                              : uploadLog.uploadStatus.isUploading
                                ? `Upload in progress: ${formatPercent(uploadLog.uploadStatus.progress)}`
                                : "Preparing to upload..."}
                          </Typography>
                          <LinearProgress
                            aria-labelledby="progress-label"
                            variant="determinate"
                            value={uploadLog.uploadStatus.progress * 100}
                          />
                        </Stack>
                      )}
                      {uploadLog.isSuccess && (
                        <SuccessAlert logId={uploadLog.data.data.id} />
                      )}
                      {/* If `errors` is empty but the upload failed, it means
                       some otherwise-unknown error occurred that isn't directly
                       related to any of the form inputs. */}
                      {uploadLog.isError && isEmpty(errors) && (
                        <Alert
                          severity="error"
                          variant="filled"
                          sx={{ width: 1 }}
                        >
                          <AlertTitle>Error</AlertTitle>
                          An unknown error occurred trying to upload the log
                        </Alert>
                      )}
                    </Stack>
                  </Card>
                </Stack>
              </Container>
            </ScrollableContainer>
          </Layout.Main>
          <Layout.Sidebar>
            <SidebarSwitch
              options={[
                {
                  sidebarId: "settings",
                  element: <SettingsSidebar />,
                },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </LayoutStateProvider>
    </>
  );
}

function SuccessAlert({ logId }: { logId: Log["id"] }) {
  const lqsNavigator = useLqsNavigator();

  return (
    <Alert severity="success" variant="filled" sx={{ width: 1 }}>
      <AlertTitle>Log Uploaded</AlertTitle>
      Your log has been uploaded and is processing.{" "}
      <Link
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId })}
        color="inherit"
      >
        View it in the player
      </Link>
      .
    </Alert>
  );
}

function buildDocsEndpoint(): string {
  return buildLqsRestApiDocsEndpoint(getAppConfig().apiEndpoint);
}
